.integrations-container{
    * {
        font-family: 'Poppins',sans-serif;
    }
    padding-left: 2rem;

    .header-container {
        
        

        .header {
            color: #41416E;
        }

        .account-option{
            padding-right: 4rem;
            justify-content: space-between;
        align-items: center;
        width: 18rem;
            h5{
                color: #41416E;
                margin-bottom: 0;
            }
            img{
                cursor: pointer;
            }
        }

    }

    
}