.hubspot-not-connected-con {
  border: 1px solid #E2E8F0;
  padding: 2rem;
  width: 80vw;
//   height: 80vh;
  margin-top: 2rem;
  border-radius: 10px;
  height: fit-content;

  .header-con {
    align-items: center;
    .hubspot-icon-big {
      height: 5rem;
      border-radius: 10px;
    }

    .connection-status {
      margin: 0 4rem;
      .name-con {
        align-items: center;
        h3 {
            margin-right: 2rem;
        }
      }
    }

    .connect-hubspot-btn {
      color: white;
      background-color: #7579ff;
      border-radius: 20px;
      padding: 4px 16px;
      height: fit-content;
      margin: 0 auto;
    }

    .unlink-hubspot-btn {
      color: white;
      background-color: #cb1111;
      border-radius: 20px;
      padding: 4px 16px;
      height: fit-content;
      margin: 0 auto;
      margin-left: 1.5rem;
    }
  }

  .info-con {
    margin-top: 2rem;
    padding-top: 1rem;
    h3 {

    }
    .features {   
    margin-left: 1rem;
    margin-top: 1rem;
        .feature-item {
            padding-bottom: 1rem;
            align-items: center;
            padding-top: 16px;
            border-bottom: 1px solid #E2E8F0;
            width: 18rem;
            .feature-img {
                height: 2rem;
                margin-right: 1rem;
                border-radius: 10px;
            }
            .feature-text {
                font-weight: 500;
            }
        }
    }
  }
}
