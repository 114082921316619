.leads-integration-container {

    >.header {
        margin-top: 2rem;
        color: #41416E;
        font-weight: 500;
        font-size: 18px;
    }

    .integration-cards-container{
        // padding-top: 1rem;
        // height: 78vh;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 18rem));
        grid-row-gap: 1rem;
        grid-column-gap: 1rem;
        overflow-y: auto;
        height: 78vh;
        align-content: start;
    }
}
