.save-endpoint-btn-con {
  p {
    color: black;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 12px !important;
  }
  div {
    gap: 2rem;
    align-items: center;
    input {
      border-radius: 8px;
      border: 1px solid var(--primary-100, #e4daff);
      background: var(--primary-0, #f5f5ff);
      padding: 8px 12px;
      // width: 16rem;
      width: 75%;
      font-size: 12px;
    }
    .disabled-input {
    }
    .enabled-input {
    }
    .save-btn {
      background-color: #5856e8;
      color: white;
      padding: 8px 8px;
      width: 6rem;
      font-size: 12px;
      border-radius: 20px;
      font-weight: 500;

    }
    .edit-btn {
      background-color: #5856e8;
      color: white;
      padding: 8px 8px;
      width: 6rem;
      text-align: center;
      font-size: 12px;
      border-radius: 20px;
      background: white;
      color: #5856e8;
      font-weight: 500;
      border: 1px solid #5856e8;
    }
  }
}
