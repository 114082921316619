.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .images_container {
    height: 40%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
      height: 100%;
    }
    .arrowImg {
      transform: rotate(-90deg);
    }
  }
  h6 {
    color: white;
  }
}