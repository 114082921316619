.main_container {
  display: flex;
  column-gap: 3rem;
  padding: 1rem 1rem 1rem 2rem;
  .active_members, .all_members {
    h4 {
      margin-bottom: 1rem;
    }
    ul {
      height: 400px;
      overflow-y: scroll;
      padding-right: 1rem;
      li {
        width: 500px;
        border: 1px solid rgba(214, 214, 214, 0.6);
        padding: 0.75rem;
        list-style: none;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        span {
          cursor: pointer;
        }
      }
      li:hover {
        background-color: black;
        color: white;
      }
    }
    ul::-webkit-scrollbar {
      width: 0.25em;
    }
     
    ul::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
     
    ul::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid rgb(144, 146, 148);
    }
  }
}