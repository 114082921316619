.trial-ended-sticky-container {
    height: 5rem;
    width: 100%;
    background: linear-gradient(90deg, #E0C3FC 0%, #8EC5FC 100%);
    align-items: center;
    justify-content: right;
    position: absolute;
    top: 0;
    z-index: 200;
    .inner-part{
    align-items: center;


    .exc-icon {
        height: 1.5rem;
    }
    .trial-exp-txt {
        color: white;
        font-size: 14px;
    }

    .upgrade-btn {
        background: linear-gradient(90deg, #2B5876 0%, #4E4376 100%);
        border-radius: 25px;
        color: white;
        font-size: 14px;
        padding: 3px 16px;
    }
    .upgrade-btn:hover {
        background: linear-gradient(90deg, #2b5876dd 0%, #4e4376dd 100%)
    }
    .upgrade-btn:active {
        background: linear-gradient(90deg, #2b5876b4 0%, #4e4376b4 100%);
    }
    .close-btn {

    }
}


}