.webhook-setup-guide-section {
    flex-basis: 50%;
    h5 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: black;
      }
      .guide-con {
        border-radius: 10px;
    border: 1px solid var(--primary-100, #E4DAFF);
    padding: 1rem 2rem;
    gap: 1rem;
    .guide-info {
        gap: 1rem;
        p{
            font-size: 14px;
            color: black;
            font-weight: 500;
        }
    }
      }

      .steps-con {
        h6{
            font-size: 16px;
            font-weight: 550;
            color: black;
            margin-bottom: 6px;
        }

        .step-items {
            list-style-type: decimal;
            li{
                font-size: 14px;
                font-weight: 500;
                color: black;
            }
        }
      }

      .buttons-con {
        gap: 1rem;
      }

      .payload-con {
        background: #F6F6F6;
    padding: 1rem;
    .title {
        font-size: 12px;
        font-weight: 600;
    }

    .data {
        margin-top: 1rem;
    }

      }
}