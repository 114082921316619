.my-account-dropdown {

    .header-item {
        color: #6E7585;
        font-size: 9px;
        padding-right: 0;
        &:hover {
            // background: white;
        }
    }

    .no-bg-on-hover {
        * {
            text-decoration: none !important;
            color: #212529;
        }
        &:hover {
            // background: white;
        }
    }

    .dropdown-menu {
        
    width: 16rem;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
    border: none;

    .dropdown-item {
        padding: 0.4rem 1.5rem;

    }

    .logout-item {
        padding: 1rem 1.5rem 0.5rem 1.5rem;
        border-top: 1px solid #F3F3F3;
    }
    }

    .main-user-img {
        height: 35px;
        border-radius: 25px;
        width: 35px;
    
    }
}