.account-item-con {
    
    // margin-bottom: 6px;

   

    img {
        height: 20px;
    }

    p {
        width: 8.5rem;
        font-size: 13px!important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400!important;
    }
    .user-email {
        width: 11.5rem;
        font-size: 10px!important;
        color: #8d8e9c;
        word-break: break-word;


    }
    .currently-active {
        background-color: #E4DAFF!important;
    font-size: 8px!important;
    color: #5956E9!important;
    padding: 3px 5px;
    align-self: center;
    border-radius: 10px;
    justify-self: flex-end;
    margin-left: auto;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500!important;
    }
}