.list-unstyled {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.list-unstyled > li {
  // width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 10px;
  margin: 0.3rem 0.5rem;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li .mm-active img {
  background: #e4daff;
  width: 2.5rem;
  height: 2.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  min-height: 0px !important;
  padding: 8px;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > .mm-active > a {
  padding: 0.625rem 12px !important;
}

#sidebar-menu > ul > .expand-btn-container {
  position: absolute !important;
  bottom: 0 !important;
}

#sidebar-menu ul li > a {
  width: 10rem;
}

#sidebar-menu ul li a:hover {
  background-color: #e4daff;
  border-radius: 20px;
  color: #5956e9;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a span {
  color: white;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
}

#sidebar-menu .expand-btn-container-inactive {
  position: absolute !important;
  bottom: 0 !important;
  width: 2rem;
  height: 2rem;
  background: #e4daff;
  margin: 1.1rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#sidebar-menu .expand-btn-container-inactive:hover {
  width: unset;
}

.organization-nav-container {
  flex-direction: column;
  height: unset !important;
  margin-left: 0;
  .nav-org {
    padding: 0.6rem 0 !important;
  }
  .mm-active {
    padding: 0.6rem 0 !important;
  }
}

// .organization-nav-container:hover .organization-nav-dropdown-container {
//     display: flex;
// }

///// New Sidebar styling

#sidebar-menu-new {
  // background-color: red;
  height: 100%;
  .sidebar-items {
    color: black;
    align-items: flex-start;
    // padding-left: 2rem;
    > li {
      list-style: none;
    }

    .expand-btn {
      cursor: pointer;
      //     position: absolute;
      //     bottom: 1rem;
      // right: 9px;
      bottom: 2rem;
      position: fixed;
      left: 18px;

      a {
        text-decoration: none;
        align-items: center;
        .close-txt {
          font-size: 10px;
          margin-top: 8px;
          color: #3d4043;
        }
      }

      .toggle-txt {
        display: none;
      }
    }

    /// toggle sidebar style when sidebar is collapsed

    .expand-btn-container-inactive {
    }

    .sidebar-item {
      // background-color: yellow;
      width: 100%;
      padding: 0.5rem 1rem 0.5rem 18px;
      > a {
        display: flex;
        color: #41416e;
        font-weight: 450;
        align-items: center;
        // justify-content: space-between  ;
        > span {
          // width: 7rem;
          font-size: 15px;
        }
        img {
          margin-right: 1rem;
          height: 1.5rem;
          width: 1.5rem;
        }
        // svg {
        //     margin-right: 1rem;
        //     height: 1.5rem;
        //     width: 1.5rem;
        // }
        .arrow-btn-container {
          .arrow-icon {
            height: 16px;
          }
        }
      }

      /// SVg color styling trick
      // img {
      //     filter: red;
      //     fill: red;
      //     color: red;
      //     filter: invert(9%) sepia(99%) saturate(5630%) hue-rotate(246deg) brightness(111%) contrast(148%);
      // }
    }

    /// Sub menu
    //  .sidebar-item {
    //     .sub-menu-dropdown-container {
    //         margin-left: 1.5rem;
    //         >a {
    //            padding: 4px 0 4px 1rem;
    //             font-size: 12px;
    //             color: #3D4043;
    //             // background-color: blanchedalmond;
    //         }
    //     }

    // }
  }
}

.sidebar-enable {
  #sidebar-menu-new {
    // background-color: red;
    height: 100%;
    .sidebar-items {
      color: black;
      align-items: flex-start;
      // padding-left: 2rem;
      > li {
        list-style: none;
      }

      .upgradeToPro {
        background-color: #F0EBFD;
        border-radius: 10px;
        margin: 3em 1em 1em 1em;
        padding: 1em 1em;
        display: flex;
        .upgradeToProLogo {
          margin: 0.5em 0.5em 0 0;
        }
        p {
          font-size: 0.9em;
          margin: 0.5em 0 !important;
        }
        h5, h6 {
          font-weight: 500;
        }
        a {
          text-decoration: none;
        }
        .upgradeToProContent{
          display: flex;
          justify-content: space-evenly;
          align-items: center;
        }
        .upgradeToProFooter {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          color: black;
        }
      }

      .expand-btn-container {
        position: fixed;
        bottom: 1rem;
        left: 12rem;
      }
      .sidebar-item {
        // background-color: yellow;
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 18px;

        .active-nav {
          .sidebar-icon-container {
            background: #5956e9 !important;
            > svg {
              > path {
                fill: white;
              }

              background: none;
            }
          }
        }

        .active-main-nav {
          background: #5956e9 !important;
          border-radius: 10px;
          .sidebar-icon-container {
            > svg {
              > path {
                fill: white;
              }

              // background: none;
            }
          }
          span {
            color: white;
            font-weight: 600;
          }
        }

        .sub-menu-dropdown-container {
          margin-left: 1.5rem;
          .sub-menu-item {
            padding: 4px 0 4px 0.8rem;
            margin-left: 0.7rem;
            font-size: 14px;
            color: #3d4043;
            text-decoration: none;
            font-weight: 500;
          }
          .active-sub-nav {
            background: #f8f5ff;
            color: #5956e9;
            font-weight: 500;
          }
          // >a {

          //     // background-color: blanchedalmond;
          // }
        }

        > a {
          display: flex;
          color: #41416e;
          font-weight: 450;
          align-items: center;
          text-decoration: none;
          // justify-content: space-between  ;
          > span {
            // display: none;
          }
          .sidebar-icon-container {
            margin-right: 0.5rem;
            height: 2.5rem;
            width: 2.5rem;
            padding: 6px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              height: 20px;
              width: 20px;
            }
            .calls-icon {
              height: 16px;
              width: 16px;
            }
            .users-icon{
              height: 22px;
              width: 22px;
            }
          }

          > svg {
            // margin-right: 1rem;
            // height: 2rem;
            // width: 2rem;
            // padding: 6px;
            // border-radius: 20px;
          }
          .arrow-btn-container {
            // display: none;
            .arrow-icon {
              height: 16px;
            }
          }
        }
      }
    }
  }
}

.vertical-collpsed {
  #sidebar-menu-new {
    // background-color: red;
    height: 100%;
    .sidebar-items {
      color: black;
      align-items: flex-start;
      // padding-left: 2rem;
      > li {
        list-style: none;
      }

      .upgradeToPro {
        display: none;
      }

      .expand-btn-container {
        // position: absolute;
        // bottom: 0;
      }
      .sidebar-item {
        // background-color: yellow;
        width: auto;
        padding: 0.9rem 0.4rem 0.9rem 14px;
        .sub-menu-dropdown-container {
          display: none;
        }
        > a {
          display: flex;
          color: #41416e;
          font-weight: 450;
          align-items: center;
          // justify-content: space-between  ;

          // &:hover >span {
          //     display: block;
          // }
          > span {
            color: #5956e9;
            background: #f8f5ff;
            // font-weight: 600;
            display: none;
            z-index: 10000;
            font-size: 14px;
          }
          img {
            margin-right: 1rem;
            height: 1.5rem;
            width: 1.5rem;
          }
          .arrow-btn-container {
            display: none;
            .arrow-icon {
              height: 16px;
            }
          }
        }

        .active-nav {
          // color: #5956E9;
          background: white;
          > svg {
            > path {
              fill: #5956e9;
            }

            background: #e4daff;
          }
        }
      }
    }
  }
}

.close-btn-cont {
  flex-direction: row;
  // width: 60px;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 25px;
  background: #f8f5ff;

  p {
    color: #3d4043;
    font-size: 10px;
  }
}

.expand-btn-cont {
  flex-direction: row;
  // width: 60px;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 25px;
  background: #f8f5ff;

  p {
    color: #3d4043;
    font-size: 10px;
  }
}

/// For tooltip on hover
.vertical-collpsed {
  // .sidebar-items {

  .sidebar-item {
    a {
      position: relative;
    }

    &:hover .sidebar-item-name {
      display: block !important;
      background: black !important;
      color: white !important;
      padding: 3px 10px;
      border-radius: 5px;
      position: initial;
      right: -6rem;
      width: fit-content !important;
      white-space: nowrap;
      transition: width 1s;
    }
  }

  // }
}
