.main_container {
  height: 100vh;
  width: 100%;
  display: flex;
  .main_content_viewer {
    width: 100%;
    header {
      padding: 1rem 1rem 1rem 2rem;
      background-color: rgba(0, 0, 0, 0.05);
      h2 {
        margin-bottom: 0;
        font-size: 2em;
      }
    }
  }
  .sidebar {
    width: 5rem;
    background-color: rgba(0, 0, 0, 0.05);
    .dashboard {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0;
      cursor: pointer;
    }
    .icon {
      font-size: 1.5rem;
    }
    p {
      font-size: 0.6rem;
    }
    .dashboard:hover {
      background-color: rgb(52, 52, 52);
      color: white;
    }
  }
}